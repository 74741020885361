(function (app) {
	'use strict';

	app = app.module('app');

	app.component('frtListbox2', {
		templateUrl: '/js/components/frtListbox2/frt-listbox.html',
		bindings: {
			items: '=',
			selectedBean: '=',
            selectedCallback: '=',
            disabled: '='

		},
		controllerAs: 'vm',
		controller: function frtListbox($rootScope, $scope, _view, _i18n, gettextCatalog) {
			var vm = this;
			vm.selected = {name: ''};

			//init selection
			if (!_.isUndefined(vm.selectedBean)) {
                setSelected(vm.selectedBean.id);
                Object.defineProperties(vm.selectedBean, {
                    'id': {
                        set: function(val) {
                            setSelected(val);
                        }
                    }
                });
			}

            function setSelected(id) {
                _.each(vm.items, function(i) {
                    if (i.id == id) {
                        vm.selected = i;
                    }
                });
            }

			vm.selectItem = function(item) {
				vm.selected = item;
                if (!_.isUndefined(vm.selectedCallback)) {
                    vm.selectedCallback(item);
                }
			}
		}
	});

}(angular));